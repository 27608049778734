import React, { useState, createContext, useContext, useEffect } from 'react';

export const PopupContext = createContext({
  isPopupOpen: false,
  setIsPopupOpen: () => {},
  disablePopup: false,
  setDisablePopup: () => {},
});

export const PopupProvider = ({ children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [disablePopup, setDisablePopup] = useState(false);

  // useEffect(() => {
  //   // Get the item from localStorage
  //   const popupData = JSON.parse(localStorage.getItem('hasShownPopup'));
  //   const currentTime = new Date().getTime();
  //   const hasShownPopup = popupData && currentTime < popupData.expiry;

  //   if (!hasShownPopup && !disablePopup) {
  //     const timer = setTimeout(() => {
  //       setIsPopupOpen(true);
  //       // Set the item in localStorage with an expiry time 4 hours from now
  //       const expiryTime = currentTime + 4 * 60 * 60 * 1000; // 4 hours in milliseconds
  //       localStorage.setItem('hasShownPopup', JSON.stringify({ value: true, expiry: expiryTime }));
  //     }, 8000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [disablePopup]);

  return (
    <PopupContext.Provider value={{ isPopupOpen, setIsPopupOpen, disablePopup, setDisablePopup }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => useContext(PopupContext);

